.addnewcourse{
    background: #A3321F;
    box-sizing: border-box;
    border: none;
    outline: none;
    font-size: 20px;
    width: max-content;
    margin-bottom: 10px;
    padding: 15px 40px;
    border-radius: 30px;
    color: white;
}
.courseshow{
    width: max-content;
    display: flex ;
    align-items: center;
    justify-content: space-between;
}
.coursedata{
    font-weight: 900;
    font-family: Poppins,sans-serif;
}
.basedon{
    color: gray;
}
.filter{
    margin-left: auto;
    display: flex;
    align-items: center;
    width: 150px;
    padding: -5px;
    float: right;
    margin-left: 55vw;
    border-radius: 30px;
    border: 1px solid #A3321F;
    justify-content: space-evenly;

}
.fa-sort-amount-down{
    color: #A3321F;
}
.newest{
    color: #A3321F;
}