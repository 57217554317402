.mainbox{
    background-color: white;
    margin: auto;
    display: flex; 
    flex-direction: column;
}
.newcourse{
    background-color: white;
    padding: 30px;
    margin: auto !important;
    border-radius: 30px;
    width: 50vw;
}
.newcin{
    border: none;
    background: #F4F4F4;
    margin: 11px;
    font-size: 15px;
    border-radius: 50px;
    width: 45%;
    padding: 13px;
}
button{
    cursor: pointer;
}
.h1{
    font-size: 24px;
    font-weight: 900;

}
.lph{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.course-btn{
    background-color: rgb(79, 182, 223);
    width: 25%;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: 11px;
    padding:  15px 20px;
    border: none;
    color: white;
    font-size: 20px;
    border-radius: 50px;
}

.fa-plus{
    width: 50px;
    background-color: rgb(79, 182, 223);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    border-radius: 50%;
    font-size: 30px;
    font-weight: 400;
}