.loginpage{
    overflow-x: hidden;
    background-color: #F4F4F4;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
} 
.logheading{
    font-size: 23px;
    font-weight: bolder;
}
button{
    cursor: pointer;
}

.loginsecter{
    width: 25vw;
    background-color: #F4F4F4;
}
.loginchanger{
    outline: none;
    padding: 20px;
    width: 50%;
    font-size: 20px;
    background-color: #F4F4F4;
    font-weight: bolder;
    border: none;
    color: #C7451C;
    border-bottom:0px solid #C7451C ;
}
.loactive{
    border-bottom-width:5px ;
}
.logbox{
    margin-top: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}
.formlogbox{
    padding: 20px 0px 20px 0px;
    flex-direction: column;
    display: flex;
    width: 97%;

}
.inputdata{
    padding: 20px;
    flex: 1;
    outline: none;
    border-radius: 10px;
    border:none;
    background-color: #F4F4F4;
    margin: 10px;
    border-radius: 25px;
    font-size: 15px;
    color: black;
}
.logsub{
    padding: 17px;
    background-color: #C7451C;
    outline: none;
    border: none;
    margin: 10px;
    color: white;
    border-radius: 30px;
    font-size: 24px;
    font-weight: bolder;
}
.hav{
    font-size: 20px;
    font-family: 'Roboto',sans-serif;
    display: inline-flex;
    margin-top: -10px;
}
.acc{
    border: none;
    outline: none;
    background-color: white;
    color: blue;
    font-size: 20px;
}
.google{
    padding: 17px;
    background-color: white;
    outline: none;
    border: 1px solid #c7441cde;
    margin: 10px;
    color: black;
    margin-top: -10px;
    width: 95%;
    border-radius: 30px;
    font-size: 20px;
    font-weight: 500;
}
.my-facebook-button-class{
    display: inline-block;
    padding: 17px;
    background-color: rgb(0, 44, 241);
    outline: none;
    border: 1px solid #2d1cc7de;
    margin: 10px;
    color: white;
    margin-top: -15px;
    width: 25vw;
    border-radius: 30px;
    font-size: 20px;
    font-weight: 500;
}
@media screen and (max-width: 56.25em){
    .loginsecter{
        width: 80vw;
        background-color: #F4F4F4;
    }
}