.sidebar{
    background: linear-gradient(180deg, #A3321F 0%, #4C0427 100%);
    width: 17vw;
    background-color: red;
    height: 100vh;
    margin-top: -15px;
    font-family: 'Poppins', sans-serif;
}
.link{
    text-decoration: none;
    color: white;
    font-size: 18px;
    padding-left: 25px;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    line-height: 40px;
}
.links{
    padding-left: 0px; 
}
.linkname{
    margin-left: 24px;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
}

.link:hover{
    background: #4C0427;

}
.icons{
    font-size: 24px;
}

.active{
    background: #4C0427;
}
@media  screen and (max-width:58.25rem) {
    .sidebar{
        width: 100vw;
        display: none;
    }
}