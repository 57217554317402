.navbar{
    flex: 1;
    overflow: hidden;
    background: linear-gradient(#550028 , #A3321F);
    /* padding: 10px; */
    box-shadow: 1px 1px 10px rgb(163, 154, 154);
    display: flex;
    height: 70px;
    align-items: center;
    justify-content: space-evenly;
}
.menurelated{
    display: flex;
    align-items: center;
}
.navicon{
    color: white;
    font-size: 25px;
}
.menuname{
    color: white;
    margin-left: 10px;
    color: white;
    font-size: 20px;
    font-family: Poppins,sans-serif;
}
.searchdiv{
    display: flex;
    align-items: center;
}

.searchbar{
    color: black;
    font-size: 15px;
    width: 20vw;
    margin-left: 30px;
    outline: none;
    border: none;
    padding: 10px;
    background-color: white;
    border-radius: 5px;
}
.uldiv{
    display: flex;
    width: 30vw;
    align-items: center;
    justify-content: space-between;
}
.navlinkdash{
    background-color: none;
    font-family: Poppins,sans-serif;
    display: inline-block;
    width: fit-content;
    padding: 4%;
    color: white;
    font-size: 20px;
    text-decoration: none;
    height: 100%;
}
.nava{
    background-color: #550028;
    display: block;
    height: 100%;
    border-bottom: 5px solid greenyellow;
}
.infodiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 15vw;
}
.navli{
    list-style: none;
}
.z{
    background-color: rgb(146, 62, 62);
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.for{
    display: flex;
    align-items: center;
}
.profile{
    width: 60px;
    height: 60px;
    background-color: rgb(180, 192, 163);
    border-radius: 50px;
    background-position: center;
}
.infodata{
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    line-height: 8px;
    margin-left: 10px;
}
.username{
    color: white;
    font-family: Poppins,sans-serif;
}
.usernamed{
    color: white;
    font-family: Poppins,sans-serif;
}
.resp{
    display: none;
}

