.topdashbar{
    flex:1;
    overflow-x: hidden;
    /* display: flex; */
    background: #F4F4F4;
    /* flex-direction: column; */
    /* align-items: center; */
}
.topdashboardheading{
    margin-right: auto;
}
.dashboardboxes{
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap ;

}

.dashboardbox{
    background: linear-gradient(180deg, #C7451C 0%, #4C0427 100%);
    width: 350px;
    /* height: 250px; */
    border-radius: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.numberof{
    background: #4E0527;
    width: 80%;
    padding: 15px;
    text-align: center;
    color: white;
    padding-top: 10px;
    margin-top: 30px;
    font-weight: 600;
    font-size: 20px;
    border-radius: 30px;
}

.students{
    margin-top: -20px;
    color: white;
    font-size: 65px;
}
.q{
    width: 80%;
    margin-top: -40px;
}
.seeall{
    color: white;
    margin-top:5px;
    line-height: 21px;
    font-weight: 400;
    font-size: 14px;
}
@media  screen and (max-width:58.25rem) {
    .topdashbar{
        width: 100vw;
        overflow-x: hidden;
        position: absolute;
    }
    .topdashboardheading{
        text-align: center;
    }
}