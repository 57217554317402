.invoice{
    width: calc(17vw - 100vw);
    padding: 20px ;
    height: 100vh;
   
}
.invoicetable{
    margin: 0px auto ;
    width: max-content;
    border-radius: 30px;
    background-color: white;
    padding: 30px;
    overflow: scroll;
}
.tabledata{
    text-align: center;
    padding: 20px;
}

.invoicetableheadrow{
    padding-top: 10px;

    
}

.idnumber::before{
    content: '#';
    font-weight: 900;
}
.idnumber{
    font-weight: 900;
}
.invoicetablehead{
    font-weight: 900;
    text-align: center;
}
.tablecellhead{
    font-weight: 900;
}

table{
    border-collapse: collapse;
}
.td:hover{
    background-color: rgba(224, 198, 198, 0.808);
}
.download{
    background-color: #bb2c00;
    padding: 10px;
    color: blanchedalmond;
    border-radius: 30px;
    font-size: 20px;
    cursor: pointer;
}
