.newp{
    display: flex;
    width: 70vw;
    height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.npc{
    background-color: white;
    min-width: 30%;
    padding: 30px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.npw-t{
    font-size: 20px;
}

 .npcf{
    width: 100%;
    padding: 10px;
    margin-left: -10px;
    margin-bottom: 10px;
    margin: 10px;
    font-size: 20px;
    border-radius: 50px;
    outline: none;
    border: none;
    /* padding: 20px; */
    background-color: rgba(210, 210, 228, 0.835);
 } 

 .npc-btn{
     margin-left: auto;
     border: none;
     font-size: 20px;
     border-radius: 50px;
     color: white;
     padding: 10px 30px;
     background-color: rgba(68, 175, 170, 0.705);
 }